<template src="./AddVaccine.html">

</template>

<script>
    import CustomDatePicker from '../../../components/CustomDatePicker.vue';

    export default {
        name: 'Vaccination',

        components: {
            CustomDatePicker,
        },

        created() {
            this.getLookups();
        },

        computed: {
            vaccines() { return this.$store.state.lookup.vaccines; },
            healthCenters() {
                return this.$store.state.lookup.healthCenters.map(obj => {
                    let rObj = {}
                    rObj["value"] = obj.value;
                    rObj["text"] = `${obj.text} - ${obj.municipalityName}`
                    return rObj
                });
            },


            //========================Rules==========================//
            checkValueRules() { return this.$validation.checkValueRules; },
            uploadFileRules() { return this.$validation.uploadFileRules; },


            //=========================== Date Limitations ===================//
            maxEmploymentDate() { return this.$helpers.getMaxMinDateString(new Date(), 0) },
            maxDate() { return this.$helpers.getMaxMinDateString(new Date(), 0) },
        },

        data: () => ({
            subscriptionValid: true,

            addDose: false,

            newVaccineValid: true,
            newVaccine: {
                vaccinationDate: null,
                vaccineId: null,
                healthCenterId: null,
                batchNo: null,
                VaccinationFileDataEntry: null,
                vaccinationFileType: null
            },

        }),

        methods: {

            vaccinationDateChanged(date) {
                this.newVaccine.vaccinationDate = date ? date : null
            },

            vaccinationFileUpload(File) {
                if (!File) return;
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.newVaccine.vaccinationFileType = e.target.result.split(',')[0].split(':')[1];
                    this.newVaccine.VaccinationFileDataEntry = e.target.result.split(',')[1];
                };
                reader.readAsDataURL(File);
            },

            clearVaccinationFile() {
                this.newVaccine.VaccinationFileDataEntry = null;
                this.newVaccine.vaccinationFileType = null;
            },


            addNewVaccine() {
                if (this.$refs.newVaccine.validate()) {
                    this.$http.addNewVaccine(this.newVaccine)
                        .then(res => {
                            this.$store.commit("snackbar/show", { message: res.data.msg, icon: "mdi-check-all", type: "success" });
                            this.back();
                        })
                        .catch(err => {
                            this.$store.commit("snackbar/show",
                                { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                            );
                        });
                }
                else {
                    this.$store.commit("snackbar/show",
                        { message: 'الرجاء التحقق من البيانات', icon: "mdi-information-outline", type: "warning" }
                    );
                }
            },

            resetSerchedData() {
                for (var key in this.newVaccine) this.newVaccine[key] = null;
            },

            back() {
                this.$router.push({ name: 'Vaccinations' })
            },

            //============= lookups ================//
            getLookups() {
                this.$store.dispatch("lookup/getVaccines");
                this.$store.dispatch("lookup/getHealthCenters");
            },

        }
    };
</script>

<style lang="scss">

    .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
        min-width: 100px;
    }



    p {
        line-height: 1.6;
    }

    .v-dialog--fullscreen {
        font-family: Tajawal;
        overflow-x: hidden;

        .v-toolbar__title {
            overflow: unset;
        }
    }

    .text-md-body-1 {
        text-align: justify;
    }

    .v-btn-toggle > .v-btn.v-btn--active span {
        color: #fff;
    }

    .v-application .hidden-sm-and-down {
        display: block !important;
    }
</style>


